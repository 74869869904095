import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { takeUntil, tap } from 'rxjs/operators';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { MatSelectChange } from '@angular/material/select';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import {
  ReportNearMissStatuses,
  Severity,
  SortDirection,
} from 'src/@hodhod/common/enum';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { NearMissReportService } from 'src/backend/services/reportings/near-miss-report.service';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { NearMissReport } from '../../models/near-miss-report';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { BaseApi } from 'src/backend/api/base-api';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { DashboardCalenderComponent } from 'src/app/dashboard/components/dashboard-calender/dashboard-calender.component';
import { ExportExcelService } from 'src/backend/services/export-excel.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { DatePipe } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-near-miss-report-list',
  templateUrl: './near-miss-report-list.component.html',
  styleUrls: ['./near-miss-report-list.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class NearMissReportListComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  reportNearMisses: NearMissReport[];
  columns: TableColumn<NearMissReport>[] = [
    {
      label: 'title',
      property: 'title',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Shift', property: 'shift', type: 'text', visible: true },
    {
      label: 'Department',
      property: 'departmentParentName',
      type: 'text',
      visible: true,
    },
    {
      label: 'SubDepartment',
      property: 'department',
      type: 'text',
      visible: true,
    },
    {
      label: 'ReportedBy',
      property: 'reportedBy',
      type: 'text',
      visible: true,
    },
    {
      label: 'TypeOfNearMiss',
      property: 'theNewTypesOfReport',
      type: 'text',
      visible: true,
    },
    { label: 'RootCause', property: 'rootCause', type: 'text', visible: true },
    { label: 'Cause', property: 'cause', type: 'text', visible: true },
    {
      label: 'UpdateAt',
      property: 'updatedOn',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'CreatedAt',
      property: 'createdOn',
      type: 'date',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Severity', property: 'saverity', type: 'boolean', visible: true },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    {
      label: 'Description',
      property: 'description',
      type: 'text',
      visible: false,
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];
  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<NearMissReport>([]);
  selection = new SelectionModel<NearMissReport>(true, []);
  searchCtrl = new UntypedFormControl();
  loadingLabel: string = '';
  searchValue: string = '';
  timer = null;
  closeForm: FormGroup;

  SharedConstants = SharedConstants;
  ReportNearMissStatus = ReportNearMissStatuses;
  SaverityStatus = Severity;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public selectedStatus: string[] = [];
  public selectedSaverity: string[] = [];

  hasAccessPermissions = ApplicationPermission.REPORTING_NEARMISS_ACCESS;
  hasWritePermissions = ApplicationPermission.REPORTING_NEARMISS_CREATE;
  hasDeletePermissions = ApplicationPermission.REPORTING_NEARMISS_DELETE;
  loggedUser: LoggedUser;
  private translationsList: any = {};
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  currentQueryParams = null;
  filteredParams = null;
  constructor(
    private router: Router,
    private nearMissReportService: NearMissReportService,
    private feedBackService: AsyncFeedbackService,
    private baseApi: BaseApi,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private exportToExcelService: ExportExcelService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private datePipe: DatePipe,
    private route: ActivatedRoute
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'ConfirmDeleteRecord'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  override ngOnInit(): void {
    this.loggedUser = this.baseApi.getUserSession();
    this.dataSource = new MatTableDataSource();

    this.currentQueryParams = this.route.snapshot.queryParams;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });

    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.selectedStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];

    this.selectedSaverity = this.currentQueryParams?.orgSaverity
      ? this.currentQueryParams?.orgSaverity.split(',')
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex: 0,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData();
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
    this.sort.sortChange.subscribe(() => {
      this.getData();
    });
  }

  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    this.filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || 'desc',
      sortField: sortField || 'createdOn',
      status: this.currentQueryParams?.orgStatus,
      saverity: this.currentQueryParams?.orgSaverity
        ? this.currentQueryParams?.orgSaverity
        : this.selectedSaverity.toString(),
      pageIndex: this.currentQueryParams?.pageIndex
        ? this.currentQueryParams?.pageIndex
        : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
      departments: this.loggedUser.userDepartments
        ?.map((ele) => ele.departmentId)
        .toString(),
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.nearMissReportService
      .getNearMissReports(this.filteredParams)
      .subscribe({
        next: (response) => {
          this.reportNearMisses = response?.data.map((item: any) => {
            return {
              ...item,
              departmentParentName: item.departmentParentName
                ? item.departmentParentName
                : item.department,
              department: item.departmentParentName ? item.department : '',
            };
          });
          this.dataSource = new MatTableDataSource(this.reportNearMisses);
          this.paginator.length = response?.totalCount;
          this.dataSource.sort = this.sort;
          this.sectionState = SectionStateStatus.Ready;
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }

  onSelectStatus(event: MatSelectChange) {
    this.selectedStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgStatus: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
  onSelectSaverity(event: MatSelectChange) {
    this.selectedSaverity = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgSaverity: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
  addReportNearMiss(): void {
    this.router.navigate([
      '/' +
        SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.REPORT_NEAR_MISS +
        '/' +
        SharedConstants.ADD_REPORT_NEAR_MISS,
    ]);
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  viewNearMissReport(event: any, id: number): void {
    this.router.navigate(
      [
        SharedConstants.REPORTING_MANAGEMENT +
          '/' +
          SharedConstants.REPORT_NEAR_MISS +
          '/' +
          SharedConstants.REPORT_NEAR_MISS_VIEW +
          '/' +
          id,
      ],
      {
        queryParams: { ...this.currentQueryParams },
      }
    );
  }

  removeNearMiss(event: any, id: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['ConfirmDeleteRecord']['Title'],
          this.translationsList['ConfirmDeleteRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.nearMissReportService.removeNearMissReport(id).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }
  exportToExcel(exportType?: string) {
    const tableHeaders = this.columns.map((item) => ({
      label: item.label.toUpperCase(),
      property: item.property.charAt(0).toUpperCase() + item.property.slice(1),
    }));
    const params: any = {
      columnHeaders: [...tableHeaders],
      reportType: 'NEAR_MISS',
      departments: this.loggedUser.userDepartments
        ?.map((ele) => ele.departmentId)
        .toString(),
      sheetName: 'NEAR_MISS',
    };
    if (exportType === 'custom') {
      const today = new Date();
      const dialogRef = this.dialog.open(DashboardCalenderComponent, {
        width: window.innerWidth < 850 ? '90%' : '60%',
        height: 'auto',
        data: {
          toDate: new Date(today),
          fromDate: new Date(today),
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        params.startDate = this.datePipe.transform(
          new Date(result?.data?.fromDate),
          'yyyy-MM-ddT00:00:00.000'
        );
        params.endDate = this.datePipe.transform(
          new Date(result?.data?.toDate),
          'yyyy-MM-ddT23:59:59.000'
        );

        this.loadingService.startLoading(true);
        this.exportToExcelService.exportToExcelFiltered(params).subscribe({
          next: (res) => {
            const a = document.createElement('a');
            const objectURL = URL.createObjectURL(res);
            a.href = objectURL;
            a.download = `nearmiss-reports-${
              params.startDate.split('T')[0]
            } - ${params.endDate.split('T')[0]}.xlsx`;
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(objectURL);
            document.body.removeChild(a);
            this.loadingService.stopLoading();
          },
          error: (error) => {
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Failure, error?.message)
            );
            this.loadingService.stopLoading();
          },
        });
      });
    } else {
      this.loadingService.startLoading(true);
      this.exportToExcelService.exportToExcel(params).subscribe({
        next: (res) => {
          const a = document.createElement('a');
          const objectURL = URL.createObjectURL(res);
          a.href = objectURL;
          a.download = 'all-nearmiss-reports.xlsx';
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(objectURL);
          document.body.removeChild(a);
          this.loadingService.stopLoading();
        },
        error: (error) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
    }
  }

  getTypeName(type) {
    return type.map((item) => item.typeName).toString();
  }
}
